import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import NavigationBar from "../../components/NavigationBar"
import whoWeAreImg from "../../images/who-we-are.png"
import whoWeHelpImg from "../../images/who-we-help.png"
import "./style.scss"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About us" />
      <div className="aboutUsPageContainer">
        <NavigationBar />
        <div className="banner">
          <div className="container">
            <h1>About Us</h1>
            <p className="summary">Who we are and what we do</p>
          </div>
        </div>
        <div className="headline">
          <div className="container">
            <div className="content">
              <p>
                Martial Arts grows confidence, develops concentration and
                promotes self discipline in kids.
              </p>
            </div>
          </div>
        </div>
        <div className="mainContent">
          <div className="container">
            {/* Who we are */}
            <div className="row">
              <div className="column">
                <div className="content">
                  <h2>Who we are</h2>
                  <p>
                    My name is Kirstie and I am the chief instructor here at
                    Martial Arts Academy Gillingham. I have been practising
                    Martial Arts &amp; Self defence throughout most of my life
                    and it has impacted my life in such a positive way. I could
                    give you an endless list of reasons to join our programme, I
                    personally promise to prioritise your child’s safety above
                    anything else.
                  </p>
                  <p>
                    We will guide and encourage them to develop their minds and
                    find the best version of themselves in learning to their
                    conquer fear and celebrate success. Finally we’ll make sure
                    that every child is always learning, always growing and
                    always ready
                  </p>
                </div>
              </div>
              <div className="column">
                <img src={whoWeAreImg} alt="Who we are" />
              </div>
            </div>

            {/* Who we help */}
            <div className="row">
              <div className="column">
                <img src={whoWeHelpImg} alt="Who we help" />
              </div>
              <div className="column">
                <div className="content">
                  <h2>Why we do it</h2>
                  <p>
                    Developing concentration skills from a young age can drive a
                    child forward to success as an adult by mastering the
                    ability to lead with a clear focus and ignore distractions.
                    Delivered in dynamic sessions, a combination of fitness, fun
                    and interesting techniques will grip and enhance your
                    child’s attention span.
                  </p>
                  <p>
                    Confidence is a natural reward for children training in
                    martial arts. As their social, physical and awareness skills
                    develop, so will their belief in themselves. As the future
                    leaders of the next generation, let’s ensure self-confident
                    children have the best qualities to inspire others. We try
                    to protect our children as much as we can, sometimes the
                    best way to do that is by ensuring they can also protect
                    themselves. Learning simple but very effective self-defence
                    techniques that young children can use in dangerous
                    situations, can be life changing for the whole family.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
